// Dependency
import React from "react"
import { ParallaxBanner } from 'react-scroll-parallax';
import Fade from 'react-reveal/Fade';

// Styles
import * as quoteStyles from './quote.module.scss'

// Assets
import quoteIcon from './assets/quoteIcon.png';
import background from './assets/quote-back.svg';

// Template
const Quote = (props) => {

    return (
        <section className={quoteStyles.cdQuoteSection}>
            <div className={`${quoteStyles.cdMaxWidth} cd-max-width`}>
                <div className={quoteStyles.cdQuoteBox}>
                    <ParallaxBanner
                        className={quoteStyles.cdQuoteParallax}
                        layers={[
                            {
                                image: background,
                                amount: 0.2,
                            }
                        ]}
                    >
                        <div className={quoteStyles.cdQuoteBoxContent}>
                            <Fade bottom cascade>
                                <div className={quoteStyles.cdQuoteBoxText}>
                                    <img className={quoteStyles.cdQuoteIcon} src={quoteIcon} alt="Quote Icon"/>
                                    <div dangerouslySetInnerHTML={{__html: props.data.content}}></div>
                                </div>
                            </Fade>
                        </div>
                    </ParallaxBanner>
                </div>
            </div>
        </section>
    )
}

export default Quote
